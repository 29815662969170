import { LocalStore, useLocalStore } from "shared/helpers/Store";

export function isUserFromSuperDispatch() {
  const searchParams = new URLSearchParams(window.location.search);
  const source = searchParams.get("utm_source");

  return source === "Web SLB" || source === "Web CTMS" || source === "Web STMS";
}

export function useUnlockUtils() {
  const hasUnlocked = useLocalStore("has_unlocked", "false");

  function unlocked() {
    LocalStore.set("has_unlocked", "true");
  }

  return {
    unlocked,
    isLocked: isUserFromSuperDispatch() ? false : hasUnlocked === "false",
  };
}
