import { Tooltip } from "@material-ui/core";
import { Color, Inline } from "@superdispatch/ui";
import { EnclosedTrailerIcon } from "../../../icons/EnclosedTrailerIcon";
import { InoperableIcon } from "../../../icons/InoperableIcon";

interface Props {
  isInoperable: boolean;
  requiresEnclosedTrailer: boolean;
}

export function VehicleConditionIcon({
  isInoperable,
  requiresEnclosedTrailer,
}: Props) {
  return (
    <Inline space="xxsmall">
      {isInoperable && (
        <Tooltip title="Inoperable Vehicle" enterDelay={800}>
          <InoperableIcon
            fontSize="small"
            htmlColor={Color.Red500}
            aria-label="Inoperable Vehicle icon"
          />
        </Tooltip>
      )}

      {requiresEnclosedTrailer && (
        <Tooltip title="Enclosed Trailer Required" enterDelay={800}>
          <EnclosedTrailerIcon
            fontSize="small"
            htmlColor={Color.Yellow500}
            aria-label="Enclosed Trailer Required icon"
          />
        </Tooltip>
      )}
    </Inline>
  );
}
