import styled from "styled-components";
import { Stack } from "@superdispatch/ui";
import { RecentPosting } from "../price-recommendation/RecentPosting";
import { Box, Button, TextBox } from "@superdispatch/ui-lab";
import magicImg from "../../../assets/magic.png";
import unlockBackgroundImage from "../../../assets/unlock_bg.png";
import { HubSpotFormDialog } from "./HubSpotFormDialog";
import { ReactNode, useState } from "react";
import { useUnlockUtils } from "./UnlockUtils";
import { trackEvent } from "../../../services/AnalyticsService";

const Container = styled.div`
  overflow: hidden;
  max-height: 870px;

  @media (min-width: 760px) {
    max-height: 615px;
  }
`;

const Blurred = styled.div`
  filter: blur(8px);
  user-select: none;
  position: relative;
`;

const BannerContainer = styled.div`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  display: inline-flex;
  padding: 24px 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  border: 1px solid #353d4d;
  background: url(${unlockBackgroundImage});
`;

const BrandButton = styled(Button)`
  background-color: #fe4a49;
`;

function UnlockBanner() {
  const { unlocked } = useUnlockUtils();
  const [open, setOpen] = useState(false);

  return (
    <BannerContainer>
      <Stack space="small" align="center">
        <img src={magicImg} alt="" height="82px" />

        <TextBox align="center" color="white" variant="heading-3">
          Discover the Magic <br /> Behind the Price
        </TextBox>

        <BrandButton
          size="large"
          data-intercom-target="pricing insights unlock"
          onClick={() => {
            setOpen(true);
          }}
        >
          Unlock Now
        </BrandButton>
      </Stack>

      <HubSpotFormDialog
        open={open}
        onEntered={() => {
          trackEvent("Viewed Unlock Now form");
        }}
        onClose={() => setOpen(false)}
        onSubmitSuccess={() => {
          trackEvent("Submitted Unlock Now form");
          setOpen(false);
          unlocked();
        }}
      />
    </BannerContainer>
  );
}

interface Props {
  children: ReactNode;
}

export function UnlockItems({ children }: Props) {
  return (
    <Container>
      <Stack space="xxsmall">
        {children}

        <Box position="relative">
          <UnlockBanner />

          <Blurred>
            <Stack space="xxsmall">
              {Array.from({ length: 5 }).map((_, index) => (
                <RecentPosting
                  key={index}
                  posting={{
                    price: 0,
                    price_per_km: 0,
                    distance: 0,
                    origin: {
                      city: "None",
                      state: "None",
                      zip: "None",
                    },
                    destination: {
                      city: "None",
                      state: "None",
                      zip: "None",
                    },
                    posted_date: "2024-04-09",
                    pickup_date: "2024-04-09",
                    vehicles: [],
                  }}
                />
              ))}
            </Stack>
          </Blurred>
        </Box>
      </Stack>
    </Container>
  );
}
