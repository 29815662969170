import React from "react";
import ReactDOM from "react-dom/client";
import { initMonitoring } from "shared/services/MonitoringService";

import { ThemeProvider } from "@superdispatch/ui";
import { QueryClientProvider, QueryClient } from "react-query";
import { App } from "./App";
import { FormsProvider } from "@superdispatch/forms";
import { getFormErrors } from "./FormErrors";

if (import.meta.env.VITE_APP_TARGET !== "local") {
  initMonitoring(
    "pricing-insights-web",
    "91d3219b-6eab-4398-b568-740dbf21c80d"
  );
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <FormsProvider getFormErrors={getFormErrors}>
          <App />
        </FormsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
