import { PricingInsightsResultDTO } from "../../../data/CarrierPayDTO";
import { Tab, Tabs } from "@material-ui/core";
import { useMemo, useState } from "react";
import { Column, Columns, Inline, Stack } from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import emptyLoadsImg from "./empty_loads.png";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import { RecentMovedLoad } from "./RecentMovedLoad";
import { RecentPosting } from "./RecentPosting";
import { addMonitoringAction } from "shared/services/MonitoringService";
import { trackCarrierPayEvent } from "../../../data/CarrierPayAnalytics";
import { useUnlockUtils } from "../unlock/UnlockUtils";
import { UnlockItems } from "../unlock/UnlockItems";

interface Props {
  pricingInsights: PricingInsightsResultDTO;
}

export function PricingRecommendationTabs({ pricingInsights }: Props) {
  const { isLocked } = useUnlockUtils();
  const [tab, $tab] = useState<"recent_moves" | "recent_postings">(
    "recent_moves"
  );
  const list = pricingInsights[tab];

  const [averagePrice, averagePricePerMile] = useMemo(() => {
    let totalPrice = 0;
    let totalPricePerMile = 0;

    if (!list.length) {
      return [0, 0];
    }

    for (let item of list) {
      totalPrice += item.price_per_vehicle;
      totalPricePerMile += item.price_per_mile_per_vehicle;
    }

    return [totalPrice / list.length, totalPricePerMile / list.length];
  }, [list]);

  return (
    <Stack space="small">
      <Box borderBottomWidth="small" borderBottomColor="Silver400">
        <Tabs
          value={tab}
          onChange={(_, value) => {
            $tab(value);
            addMonitoringAction("tab_change", { value });
            trackCarrierPayEvent({
              name: "Pricing Insights: UI Interaction",
              type:
                value === "recent_moves"
                  ? "Clicked Recent Moves"
                  : "Clicked Recent Postings",
            });
          }}
        >
          <Tab value="recent_moves" label="Recent Moves" />
          <Tab value="recent_postings" label="Posted to Super Loadboard" />
        </Tabs>
      </Box>

      <Columns space={["small", "small", "large"]} collapseBelow="desktop">
        <Column width="content">
          <Inline space={["xsmall", "large"]}>
            <Inline>
              <TextBox color="secondary">Avg price/vehicle</TextBox>
              <TextBox>
                {formatCurrency(averagePrice, {
                  maximumFractionDigits: 0,
                })}
              </TextBox>
            </Inline>

            <Inline>
              <TextBox color="secondary">Avg price/mi</TextBox>
              <TextBox>
                {formatCurrency(averagePricePerMile, {
                  maximumFractionDigits: 2,
                })}
              </TextBox>
            </Inline>
          </Inline>
        </Column>
      </Columns>

      {tab === "recent_moves" &&
        (isLocked ? (
          <UnlockItems>
            {pricingInsights.recent_moves[0] && (
              <RecentMovedLoad load={pricingInsights.recent_moves[0]} />
            )}
          </UnlockItems>
        ) : list.length === 0 ? (
          <Box marginTop={["none", "xxlarge"]} paddingTop={["none", "large"]}>
            <Stack align="center">
              <img src={emptyLoadsImg} alt="" width="70" />
              <TextBox color="secondary" variant="body-semibold" align="center">
                Currently, there are no recently delivered loads. <br /> Change
                the route and try again.
              </TextBox>
            </Stack>
          </Box>
        ) : (
          <Stack>
            {pricingInsights.recent_moves.map((item, index) => (
              <RecentMovedLoad key={index} load={item} />
            ))}
          </Stack>
        ))}

      {tab === "recent_postings" &&
        (isLocked ? (
          <UnlockItems>
            {pricingInsights.recent_postings[0] && (
              <RecentPosting posting={pricingInsights.recent_postings[0]} />
            )}
          </UnlockItems>
        ) : list.length === 0 ? (
          <Box marginTop={["none", "xxlarge"]} paddingTop={["none", "large"]}>
            <Stack align="center">
              <img src={emptyLoadsImg} alt="" width="70" />
              <TextBox color="secondary" variant="body-semibold" align="center">
                Currently, there are no posted loads for this route. <br />
                Change the route and try again.
              </TextBox>
            </Stack>
          </Box>
        ) : (
          <Stack>
            {pricingInsights.recent_postings.map((item, index) => (
              <RecentPosting key={index} posting={item} />
            ))}
          </Stack>
        ))}
    </Stack>
  );
}
