import { RecentPostingDTO } from "../../../data/CarrierPayDTO";
import { Card, CardContent } from "@material-ui/core";
import { Stack } from "@superdispatch/ui";
import { LoadWaypoints } from "./LoadWaypoints";
import { PostingVehicle } from "./LoadVehicle";
import { DescriptionItem } from "@superdispatch/ui-lab";
import { CalendarOut } from "../../../icons/CalendarOut";
import { formatDate, parseDate } from "@superdispatch/dates";
import { TextBox } from "@superdispatch/ui-lab";
import { convertPricePerKmToPricePerMile } from "shared/utils/MeasurementUtils";
import { PostingVehiclesSummary } from "./PostingVehiclesSummary";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import styled from "styled-components";

const StyledCardContent = styled(CardContent)`
  padding: 12px 16px;

  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr)) auto;

  @media (max-width: 1350px) {
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 1000px) {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr;
  }
`;

export function RecentPosting({ posting }: { posting: RecentPostingDTO }) {
  return (
    <Card>
      <StyledCardContent>
        <LoadWaypoints load={posting} />

        <Stack space="xsmall">
          {posting.vehicles.slice(0, 2).map((vehicle, index) => (
            <PostingVehicle key={index} vehicle={vehicle} />
          ))}

          {posting.vehicles.length > 2 && (
            <PostingVehiclesSummary
              title={`+ ${posting.vehicles.slice(2).length} more`}
              vehicles={posting.vehicles.slice(2)}
            />
          )}
        </Stack>

        <Stack>
          <DescriptionItem label="Posted" icon={<CalendarOut />}>
            {formatDate(
              parseDate(posting.posted_date, {
                format: "DateISO",
              }),
              { variant: "Date" }
            )}
          </DescriptionItem>

          <DescriptionItem label="Available" icon={<CalendarOut />}>
            {formatDate(
              parseDate(posting.pickup_date, {
                format: "DateISO",
              }),
              { variant: "Date" }
            )}
          </DescriptionItem>
        </Stack>

        <Stack space="xxsmall" align={["left", "left", "right"]}>
          <TextBox variant="heading-4">{formatCurrency(posting.price)}</TextBox>
          <TextBox color="secondary">
            {formatCurrency(
              convertPricePerKmToPricePerMile(posting.price_per_km, 2)
            )}
            /mi
          </TextBox>
        </Stack>
      </StyledCardContent>
    </Card>
  );
}
