import styled from "styled-components";
import { ShippingDetailsDTO } from "../../data/ShippingDetailsDTO";
import { useState } from "react";
import { PricingInsightsResultDTO } from "../../data/CarrierPayDTO";
import { ShippingDetailsForm } from "./shipping-details/ShippingDetailsForm";
import { PricingRecommendation } from "./price-recommendation/PricingRecommendation";

const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export function PricingInsightsPage() {
  const [shippingDetails, $shippingDetails] =
    useState<ShippingDetailsDTO | null>(null);
  const [pricingInsights, $pricingInsights] =
    useState<PricingInsightsResultDTO | null>(null);

  return (
    <Content>
      <ShippingDetailsForm
        pricingInsights={pricingInsights}
        onSubmit={$shippingDetails}
        onSubmitSuccess={$pricingInsights}
      />

      <PricingRecommendation
        pricingInsights={pricingInsights}
        shippingDetails={shippingDetails}
      />
    </Content>
  );
}
