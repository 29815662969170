import { createSvgIcon } from "./IconUtils";

export const EnclosedTrailerIcon = createSvgIcon(
  "EnclosedTrailer",
  <>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.077 3h9.846a4.392 4.392 0 014.381 4.084l.685 9.743a4.392 4.392 0 01-4.381 4.7H6.392a4.392 4.392 0 01-4.381-4.7l.685-9.743A4.392 4.392 0 017.077 3zm0 1.882a2.51 2.51 0 00-2.503 2.334l-.685 9.743a2.51 2.51 0 002.503 2.685h11.216a2.51 2.51 0 002.503-2.685l-.684-9.743a2.51 2.51 0 00-2.504-2.334H7.077z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.85 14.977l.012 1.345c.004.339-.092.656-.257.929l-.02.032c.04.121.061.251.062.387l.003 1.254a1.296 1.296 0 01-1.24 1.3l-.49.015a1.212 1.212 0 01-1.248-1.223l-.002-.666-7.27.03.008.897a.922.922 0 01-.903.92l-.911.023a.894.894 0 01-.845-.527.876.876 0 01-.075-.347L5.66 17.83a1.824 1.824 0 01-.192-.249 1.714 1.714 0 01-.275-.915l-.012-1.345a1.803 1.803 0 01.69-1.428c.143-.114.302-.206.472-.274l.313-.795.555-1.411a3.188 3.188 0 01.794-1.171 3.244 3.244 0 012.148-.876l3.617-.091c.328-.009.647.032.95.115.45.125.865.348 1.214.652.348.303.629.686.816 1.13l.58 1.383.328.78c.342.118.638.335.852.623.21.284.337.635.34 1.018zm-12.266.731c.004-.532.374-.901.927-.925.551-.022 1.374.74 1.37 1.272-.003.467-.642.482-1.171.494-.074.002-.145.003-.212.006-.552.023-.918-.315-.914-.847zm9.016-2.547l-.559-1.34a1.219 1.219 0 00-.204-.33 1.321 1.321 0 00-1.059-.442l-3.618.152a1.454 1.454 0 00-1.275.878l-.578 1.387 5.072-.212 2.22-.093zm-.414 2.894c.004-.528.834-1.357 1.384-1.38.55-.023.915.315.911.844-.004.529-.373.897-.923.92l-.21.011c-.528.032-1.165.07-1.162-.395z"
      clipRule="evenodd"
    />
  </>
);
