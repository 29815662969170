import { trackEvent } from "../services/AnalyticsService";
import { ShippingDetailsDTO } from "./ShippingDetailsDTO";
import { CarrierPayDTO } from "./CarrierPayDTO";
import { formatAddressLine } from "shared/helpers/AddressHelpers";

type Event =
  | {
      name: "Calculated Carrier Pay Price";
      isRecalculated: boolean;
      shippingDetails: ShippingDetailsDTO;
    }
  | {
      name: "Copied Price from Pricing Insights";
      shippingDetails: ShippingDetailsDTO;
      carrierPay: CarrierPayDTO;
    }
  | {
      name: "Clicked What is Pricing Insights Link";
    }
  | {
      name: "Cleared Fields";
    }
  | {
      name: "Clicked Shipper TMS Access";
    }
  | {
      name: "Clicked Carrier TMS Access";
    }
  | {
      name: "Clicked Contact Support";
    }
  | {
      name: "Pricing Insights: UI Interaction";
      type:
        | "Clicked Recent Moves"
        | "Clicked Recent Postings"
        | "Pressed 'Later' button";
      utm_source?: string;
      utm_medium?: string;
    };

export function trackCarrierPayEvent(event: Event) {
  switch (event.name) {
    case "Calculated Carrier Pay Price": {
      const { trailer_type, vehicles, destination, origin } =
        event.shippingDetails;
      trackEvent(event.name, {
        vehicle_count: vehicles?.length,
        trailer_type_enclosed: trailer_type === "Enclosed",
        origin: formatAddressLine(origin.zip, origin.city, origin.state),
        destination: formatAddressLine(
          destination.zip,
          destination.city,
          destination.state
        ),
        isRecalculated: event.isRecalculated,
      });
      break;
    }
    case "Copied Price from Pricing Insights": {
      const { trailer_type, vehicles, destination, origin } =
        event.shippingDetails;
      const { total_price } = event.carrierPay;
      trackEvent("Calculated Carrier Pay Price event", {
        price: total_price,
        vehicle_count: vehicles?.length,
        trailer_type_enclosed: trailer_type === "Enclosed",
        origin: formatAddressLine(origin.zip, origin.city, origin.state),
        destination: formatAddressLine(
          destination.zip,
          destination.city,
          destination.state
        ),
      });
      break;
    }
    default:
      const { name, ...props } = event;
      trackEvent(name, props);
  }
}
