import { AppBar, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { Color, Inline, Tag, useResponsiveValue } from "@superdispatch/ui";
import React from "react";
import { LogoWithBrand } from "./LogoWithBrand";
import styled from "styled-components";
import { TextBox } from "@superdispatch/ui-lab";
import { trackCarrierPayEvent } from "./data/CarrierPayAnalytics";

const HeaderContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1362px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled.a`
  color: ${Color.Dark500};
  text-decoration: none;
  display: flex;
`;

interface Props {
  actions?: React.ReactNode;
}

export function AppHeader({ actions }: Props) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <AppBar position="sticky">
      <Toolbar>
        <HeaderContainer>
          <Inline verticalAlign="center" space="small">
            <LogoLink href="https://superdispatch.com/">
              <LogoWithBrand />
            </LogoLink>

            {!isMobile && (
              <Inline verticalAlign="center" space="xsmall">
                <TextBox variant={"heading-2"}>Pricing Insights</TextBox>
                <Tag color="teal" variant="subtle">
                  FREE
                </Tag>
              </Inline>
            )}

            {!isMobile && (
              <Tooltip
                title={
                  <>
                    Using the Pricing Insights <br />
                    you're able to quickly understand <br />
                    the transportation cost prior to <br />
                    quoting your customers and <br />
                    prior to posting your orders.
                  </>
                }
                onClick={() => {
                  trackCarrierPayEvent({
                    name: "Clicked What is Pricing Insights Link",
                  });
                }}
              >
                <Typography
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  What is Pricing Insights?
                </Typography>
              </Tooltip>
            )}
          </Inline>

          {actions}
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
}
