import { Button } from "@superdispatch/ui-lab";
import React from "react";
import styled from "styled-components";
import headerBackground from "./assets/header.png";
import footerBackground from "./assets/footer.png";
import statsImg from "./assets/stats.png";
import customerImg from "./assets/customer.png";
import logoImg from "./assets/logo.png";
import logoWhiteImg from "./assets/logo-white.png";
import { Divider, Link, Typography } from "@material-ui/core";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Phone,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from "@superdispatch/ui";
import { showIntercomLauncher } from "./services/IntercomService";

const Wrapper = styled.div`
  background: #fff;
`;

const Content = styled.div`
  padding-bottom: 16px;

  @media (min-width: 760px) {
    padding: 0 32px;
    margin-top: -80px;
  }
`;

const TextBodyLarge = styled(Typography)`
  font-size: 22px;
  line-height: 32px;
`;

const TextBodyMedium = styled(Typography)`
  font-size: 18px;
  line-height: 21px;
`;

const TextSubtitle = styled(Typography)`
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
`;

const TextHeading3 = styled(Typography)`
  font-family: "Metropolis", sans-serif;
  font-size: 39px;
  font-weight: 600;
  line-height: 48px;
`;

const TextHeading2 = styled(Typography)`
  font-family: "Metropolis", sans-serif;
  font-size: 57px;
  line-height: 68px;
  font-weight: 600;
`;

const TextHeading4 = styled(Typography)`
  font-family: "Metropolis", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
`;

const Quote = styled(TextHeading4)`
  position: relative;
  color: #ffffff;

  ::before {
    left: -12px;
    position: absolute;
    content: "“";
  }

  ::after {
    content: "”";
  }
`;

const StyledButton = styled(Button)`
  background: #fe4a49;
  border-radius: 8px;
`;

const Header = styled.div`
  padding: 16px 32px;

  background: #222f44 url(${headerBackground});
  background-size: cover;
`;

const TitleContainer = styled.div`
  color: #fff;
  margin-top: 32px;
  margin-bottom: 40px;

  @media (min-width: 760px) {
    margin-top: 96px;
    margin-bottom: 144px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;
  margin: 40px auto;

  @media (min-width: 760px) {
    margin: 80px auto;
  }
`;

const StatsImage = styled.img`
  width: 100%;

  @media (min-width: 760px) {
    width: 568px;
  }
`;

const FooterDivider = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 48px;
  background: ${Color.Dark450};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px 32px;
  margin-bottom: -80px;

  background: #222f44 url(${footerBackground});
  background-size: cover;
`;

const QuoteContainer = styled.div`
  margin: 32px 24px;
  color: #fff;

  @media (min-width: 760px) {
    margin: 100px auto;
  }
`;

const CustomerImage = styled.img`
  border-radius: 48px 8px;
`;

const SocialContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 64px;
  color: #fff;

  @media (min-width: 760px) {
    margin: 80px 134px;
  }
`;

interface Props {
  children: React.ReactNode;
}

export function MarketingLayout({ children }: Props) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <Wrapper>
      <Header>
        <Columns space="medium" align="center">
          <Column>
            <a href="https://superdispatch.com">
              <img src={logoImg} alt="logo" height="40px" />
            </a>
          </Column>

          {!isMobile ? (
            <Column width="content">
              <Inline>
                <Phone color="action" fontSize="small" />
                <Link
                  href="tel:+18169747002"
                  underline="none"
                  color="inherit"
                  style={{ color: "white" }}
                >
                  +1 (816) 974-7002
                </Link>
              </Inline>
            </Column>
          ) : (
            <Link href="tel:+18169747002" underline="none" color="inherit">
              <Phone color="action" fontSize="large" />
            </Link>
          )}

          {!isMobile && (
            <Column width="content">
              <StyledButton
                size="large"
                data-intercom-target="pricing insights chat with sales"
                onClick={() => {
                  showIntercomLauncher();
                }}
              >
                Chat with Sales
              </StyledButton>
            </Column>
          )}
        </Columns>

        <TitleContainer>
          {isMobile ? (
            <Stack space="medium">
              <Stack space="xsmall">
                <TextHeading4 align="center" variant="h4" color="inherit">
                  Pricing Insights You Can Trust
                </TextHeading4>

                <Typography
                  align="center"
                  color="textSecondary"
                  style={{ color: Color.Silver500 }}
                >
                  Unlock real-time pricing <br /> recommendations to expedite{" "}
                  <br />
                  customer quotes, accelerate load <br /> acceptance, and
                  maximize your profit.
                </Typography>
              </Stack>

              <Column width="content">
                <Inline horizontalAlign="center">
                  <StyledButton
                    size="large"
                    data-intercom-target="pricing insights chat with sales"
                    onClick={() => {
                      showIntercomLauncher();
                    }}
                  >
                    Chat with Sales
                  </StyledButton>
                </Inline>
              </Column>
            </Stack>
          ) : (
            <Stack space="large">
              <TextHeading2 align="center" variant="h2" color="inherit">
                Pricing Insights You Can Trust
              </TextHeading2>

              <TextSubtitle
                align="center"
                color="textSecondary"
                style={{
                  color: Color.Silver500,
                }}
              >
                Unlock real-time pricing recommendations to expedite customer
                quotes, <br />
                accelerate load acceptance, and maximize your profit.
              </TextSubtitle>
            </Stack>
          )}
        </TitleContainer>
      </Header>

      <Content>{children}</Content>

      <StatsContainer>
        <div>
          <Columns
            align="center"
            space={["medium", "medium", "xxlarge"]}
            collapseBelow="desktop"
          >
            <Column width="content">
              <Stack space="large">
                <TextHeading3
                  variant="h3"
                  align={isMobile ? "center" : undefined}
                >
                  How does it work?
                </TextHeading3>
                <TextBodyLarge
                  color="textSecondary"
                  align={isMobile ? "center" : undefined}
                  style={{ maxWidth: isMobile ? undefined : 520 }}
                >
                  Our advanced machine-learning algorithm analyzes recently
                  moved and posted loads, determining the most competitive rate
                  necessary to get your load booked and moved fast.
                </TextBodyLarge>
              </Stack>
            </Column>

            <Column width="content">
              <StatsImage src={statsImg} alt="How does it work?" />
            </Column>
          </Columns>
        </div>
      </StatsContainer>

      <Footer>
        <QuoteContainer>
          <Inline space="xxlarge" horizontalAlign="center">
            <CustomerImage src={customerImg} alt="" />

            <Stack space="medium">
              <Quote variant="h4">
                Super Dispatch’s pricing tools have significantly <br />{" "}
                assisted us to book our loads faster by providing us <br /> with
                the most up to date pricing insights.
              </Quote>

              <TextBodyMedium variant="body1">
                Dennis Robinson,{" "}
                <TextBodyMedium display="inline" color="textSecondary">
                  Auto Lenders
                </TextBodyMedium>
              </TextBodyMedium>
            </Stack>
          </Inline>
        </QuoteContainer>

        <SocialContainer>
          <Columns collapseBelow="tablet" space={["large", "small"]}>
            <Column>
              <Inline horizontalAlign={["center", "center", "left"]}>
                <a href="https://superdispatch.com">
                  <img src={logoWhiteImg} alt="" height="32px" />
                </a>
              </Inline>
            </Column>

            <Column width="content">
              <Inline horizontalAlign="center">
                <Phone color="action" fontSize="small" />
                <Link href="tel:+18169747002" color="textSecondary">
                  +1 (816) 974-7002
                </Link>
              </Inline>
            </Column>

            <Column width="content">
              <Inline horizontalAlign="center" space="medium">
                <a target="_blank" href="https://twitter.com/SuperDispatch">
                  <Twitter color="action" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/super-dispatch/"
                >
                  <LinkedIn color="action" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/super_dispatch/"
                >
                  <Instagram color="action" />
                </a>

                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=61560089459751"
                >
                  <Facebook color="action" />
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCaDAkKBvaZA5naYkT0AOKuw"
                >
                  <YouTube color="action" />
                </a>
              </Inline>
            </Column>
          </Columns>

          <FooterDivider />

          <Columns>
            {!isMobile && (
              <Column>
                <Inline space={["small", "large"]}>
                  <Link
                    href="https://superdispatch.com/terms-of-service/"
                    color="textSecondary"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    href="https://superdispatch.com/privacy-policy/"
                    color="textSecondary"
                  >
                    Privacy Police
                  </Link>
                </Inline>
              </Column>
            )}

            <Column width="content">
              <Typography color="textSecondary">
                © Super Dispatch. All rights reserved.
              </Typography>
            </Column>
          </Columns>
        </SocialContainer>
      </Footer>
    </Wrapper>
  );
}
