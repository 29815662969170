import { array, boolean, InferType, number, object, string } from "yup";
import { generateVehicleKey } from "./ShippingDetailsDTO";

export type ShippingDetailsParamsDTO = InferType<
  typeof shippingDetailsParamsSchema
>;
export const shippingDetailsParamsSchema = object({
  origin: object({
    zip: string().nullable(),
    city: string().nullable(),
    state: string().nullable(),
  })
    .nullable()
    .default(null),
  destination: object({
    zip: string().nullable(),
    city: string().nullable(),
    state: string().nullable(),
  })
    .nullable()
    .default(null),
  trailer_type: string().nullable().default("Open"),
  vehicles: array(
    object({
      $key: string().default(generateVehicleKey),
      make: string().nullable().default(null),
      model: string().nullable().default(null),
      type: string()
        .nullable()
        .default(null)
        .transform((value) => {
          // Transform types from Shipper and Carrier
          return value === "pickup"
            ? "pickup_2_doors"
            : value === "4_door_pickup"
            ? "pickup_4_doors"
            : value;
        }),
      is_inoperable: boolean().default(false),
      year: number()
        .nullable()
        .default(null)
        .transform((value, originalValue, context) => {
          // do not throw error if type missmatch
          return context.isType(value) ? value : null;
        }),
    })
  )
    .default([
      {
        $key: generateVehicleKey(),
        model: null,
        make: null,
        type: null,
        is_inoperable: false,
        year: null,
      },
    ])
    .nullable(),
  model_version: string().nullable(),
  service: string().nullable(),
}).noUnknown();
