import { round } from "lodash-es";

const MILE_TO_KM_MULTIPLIER = 1.609344;

function convert(
  value: number,
  multiplier: number,
  precision?: number
): number {
  const converted = value * multiplier;

  return precision == null ? converted : round(converted, precision);
}

export function mileToKm(value: number, precision?: number): number {
  return convert(value, MILE_TO_KM_MULTIPLIER, precision);
}

export function kmToMile(value: number, precision?: number): number {
  return convert(value, 1 / MILE_TO_KM_MULTIPLIER, precision);
}

export function convertPricePerMileToPricePerKm(
  value: number,
  precision?: number
): number {
  return convert(value, 1 / MILE_TO_KM_MULTIPLIER, precision);
}

export function convertPricePerKmToPricePerMile(
  value: number,
  precision?: number
): number {
  return convert(value, MILE_TO_KM_MULTIPLIER, precision);
}
