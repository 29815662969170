import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useVehicleMakes } from "../../../data/VehiclesAPI";
import { ChangeEvent, FocusEvent } from "react";
import { useField, useFormikContext } from "formik";
import { TextBox } from "@superdispatch/ui-lab";

export function VehicleMakeAutocompleteField({ name }: { name: string }) {
  const { isSubmitting } = useFormikContext();
  const { data = [] } = useVehicleMakes();
  const [{ value, onBlur }, { error, touched }, { setValue }] =
    useField<string>({
      name,
    });
  const errorText = touched && error;

  return (
    <Autocomplete
      loading={!data}
      options={data}
      freeSolo={true}
      forcePopupIcon={false}
      multiple={false}
      selectOnFocus={true}
      disabled={isSubmitting}
      disableClearable={true}
      value={value || ""}
      filterOptions={(options) =>
        !value
          ? options
          : options.filter((x) => x.toLowerCase().includes(value.toLowerCase()))
      }
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        onBlur(event.target.value);
      }}
      onInput={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      onChange={(_, nextValue) => {
        setValue(nextValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorText}
          helperText={errorText}
          label={
            <>
              Make <TextBox color="secondary">(Required)</TextBox>
            </>
          }
        />
      )}
    />
  );
}
