import { Color } from "@superdispatch/ui";
import { createSvgIcon } from "./IconUtils";

export const PickupAltIcon = createSvgIcon(
  "PostingFilterPickup",
  <>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={Color.White}
      d="M17 11.087l-1.196 1.217-2.966-3.036V18h-1.676V9.268l-2.966 3.036L7 11.087 12.004 6 17 11.087z"
    />
  </>
);
