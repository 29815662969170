import { RadioField } from "@superdispatch/ui";
import { FormikRadioGroupField } from "@superdispatch/forms";

export function TrailerTypeField({ name }: { name: string }) {
  return (
    <FormikRadioGroupField name={name} RadioGroupProps={{ row: true }}>
      <RadioField label="Open" value="Open" />
      <RadioField label="Enclosed" value="Enclosed" />
    </FormikRadioGroupField>
  );
}
