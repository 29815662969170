import { createSvgIcon } from "./IconUtils";

export const HorizontalSwapIcon = createSvgIcon(
  "HorizontalSwap",
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M15.206 4.115L16.228 3 20.5 7.66l-4.272 4.661-1.022-1.115 2.55-2.768H4.076V6.883h13.678l-2.55-2.768zM9.294 12.794l-1.022-1.115L4 16.339 8.272 21l1.022-1.115-2.55-2.768h13.679v-1.555H6.745l2.549-2.768z"
    fill="currentColor"
  />
);
