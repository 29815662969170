import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useVehicleModels } from "../../../data/VehiclesAPI";
import { ChangeEvent, FocusEvent } from "react";
import { useField, useFormikContext } from "formik";
import { TextBox } from "@superdispatch/ui-lab";
import { useValueObserver } from "@superdispatch/hooks";

export function VehicleModelAutocompleteField({
  name,
  make,
}: {
  name: string;
  make: string | null;
}) {
  const { isSubmitting } = useFormikContext();
  const { data = [] } = useVehicleModels(make);
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField<
    string | null
  >({ name });
  const errorText = touched && error;

  useValueObserver(make, () => {
    setValue(null);
  });

  return (
    <Autocomplete
      loading={!data}
      options={data}
      freeSolo={true}
      forcePopupIcon={false}
      multiple={false}
      selectOnFocus={true}
      disableClearable={true}
      value={value || ""}
      disabled={isSubmitting}
      filterOptions={(options) =>
        !value
          ? options
          : options.filter((x) => x.toLowerCase().includes(value.toLowerCase()))
      }
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        onBlur(event.target.value);
      }}
      onInput={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      onChange={(_, nextValue) => {
        setValue(nextValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorText}
          helperText={errorText}
          label={
            <>
              Model <TextBox color="secondary">(Required)</TextBox>
            </>
          }
        />
      )}
    />
  );
}
