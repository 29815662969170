import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { ShippingDetailsDTO } from "../../../data/ShippingDetailsDTO";
import { useEffect, useRef } from "react";
import { ContentCopyIcon } from "../../../icons/ContentCopyIcon";
import { useClipboard } from "shared/helpers/DomHelpers";
import { PricingInsightsResultDTO } from "../../../data/CarrierPayDTO";
import {
  convertPricePerKmToPricePerMile,
  kmToMile,
} from "shared/utils/MeasurementUtils";
import { trackCarrierPayEvent } from "../../../data/CarrierPayAnalytics";
import emptyRecommendationImg from "./empty_recommendation.png";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import { PricingRecommendationTabs } from "./PricingRecommendationTabs";
import { addMonitoringAction } from "shared/services/MonitoringService";
import { round } from "lodash-es";

const Content = styled.div`
  flex: 1;
  padding: 24px;
  width: 720px;
  max-width: 720px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};

  @media (max-width: 760px) {
    width: 100%;
  }

  @media (min-width: 760px) {
    height: max(100vh - 112px, 800px);
    overflow: auto;
  }

  @media (min-width: 1440px) {
    max-width: 912px;
  }
`;

export function PricingRecommendation({
  pricingInsights,
  shippingDetails,
}: {
  shippingDetails: ShippingDetailsDTO | null;
  pricingInsights: PricingInsightsResultDTO | null;
}) {
  const ref = useRef<HTMLElement | null>(null);
  const { copy, status } = useClipboard();
  const { addSnackbar } = useSnackbarStack();
  const isMobile = useResponsiveValue(true, false);
  const carrierPay = pricingInsights?.carrier_pay;

  useEffect(() => {
    if (status === "copied") {
      addSnackbar("Price Copied.");
    }
  }, [status, addSnackbar]);

  useEffect(() => {
    if (pricingInsights && isMobile) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [pricingInsights, ref, isMobile]);

  if (!pricingInsights) {
    return (
      <Content>
        <Box marginTop={["none", "xxlarge"]} paddingTop={["none", "large"]}>
          <Stack align="center">
            <img src={emptyRecommendationImg} alt="" width="258" />
            <TextBox color="secondary" variant="heading-3" align="center">
              Start with your Shipping Details
            </TextBox>

            <TextBox color="secondary" align="center">
              Enter route and vehicle details <br /> to get your Pricing
              Recommendation.
            </TextBox>
          </Stack>
        </Box>
      </Content>
    );
  }

  return (
    <Content>
      <Stack space="medium">
        <Stack space="small">
          <TextBox ref={ref} id="pricing-recommendation" variant="heading-2">
            Pricing Recommendation
          </TextBox>

          {!!carrierPay ? (
            <Stack space="none">
              <TextBox>Estimated Carrier Price</TextBox>
              <Columns space="medium" align="center">
                <Column width={["fluid", "content"]}>
                  <Inline space="xxsmall" verticalAlign="center">
                    <TextBox variant="heading-1">
                      {formatCurrency(round(carrierPay.total_price))}
                    </TextBox>

                    <IconButton
                      size="small"
                      style={{ fontSize: 16 }}
                      onClick={() => {
                        copy(String(round(carrierPay.total_price)));
                        if (shippingDetails) {
                          trackCarrierPayEvent({
                            name: "Copied Price from Pricing Insights",
                            carrierPay,
                            shippingDetails,
                          });
                        }

                        addMonitoringAction("copy");
                      }}
                    >
                      <ContentCopyIcon fontSize="inherit" color="action" />
                    </IconButton>
                  </Inline>
                </Column>

                <Column width="content">
                  <Tooltip title="Price per Vehicle">
                    <TextBox color="secondary">
                      <Link href="#" color="inherit" underline="always">
                        {formatCurrency(
                          convertPricePerKmToPricePerMile(
                            carrierPay.price_per_km /
                              (shippingDetails?.vehicles?.length || 1),
                            2
                          )
                        )}
                        /mi
                      </Link>{" "}
                      · {kmToMile(carrierPay.distance, 2)} mi
                    </TextBox>
                  </Tooltip>
                </Column>
              </Columns>
            </Stack>
          ) : (
            <Stack space="none">
              <TextBox>Estimated Carrier Price</TextBox>
              <TextBox variant="heading-2">N/A</TextBox>
            </Stack>
          )}
        </Stack>

        <PricingRecommendationTabs pricingInsights={pricingInsights} />
      </Stack>
    </Content>
  );
}
