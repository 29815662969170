import { AnchorButton } from "@superdispatch/ui-lab";
import React, { useMemo } from "react";
import { AppHeader } from "./AppHeader";
import styled from "styled-components";
import { trackCarrierPayEvent } from "./data/CarrierPayAnalytics";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 24px;
`;

function LoginButton() {
  const source = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("utm_source");
  }, []);

  if (source === "Web SLB" || source === "Web CTMS") {
    return (
      <AnchorButton
        size="medium"
        target="_blank"
        variant="neutral"
        href="https://superdispatch.com/signup/carrier"
        onClick={() => {
          trackCarrierPayEvent({
            name: "Clicked Carrier TMS Access",
          });
        }}
      >
        Carrier TMS Access
      </AnchorButton>
    );
  }

  return (
    <AnchorButton
      size="medium"
      target="_blank"
      variant="neutral"
      href="https://superdispatch.com/signup/shipper"
      onClick={() => {
        trackCarrierPayEvent({
          name: "Clicked Shipper TMS Access",
        });
      }}
    >
      Shipper TMS Access
    </AnchorButton>
  );
}

interface Props {
  children: React.ReactNode;
}

export function AppLayout({ children }: Props) {
  return (
    <Wrapper>
      <AppHeader actions={<LoginButton />} />

      <Container>{children}</Container>
    </Wrapper>
  );
}
