import { Tooltip, Typography } from "@material-ui/core";
import { Inline, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import { formatVehicleTitle } from "./LoadVehicle";
import { RecentMovedLoadVehicleDTO } from "../../../data/CarrierPayDTO";

interface PostingVehiclesSummaryProps {
  title: string;
  vehicles: RecentMovedLoadVehicleDTO[];
}

export function PostingVehiclesSummary({
  title,
  vehicles,
}: PostingVehiclesSummaryProps) {
  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Tooltip
        enterDelay={800}
        title={
          <Stack space="xxsmall">
            {vehicles.map((vehicle, index) => (
              <TextBox key={index} color="white">
                {formatVehicleTitle(vehicle)}
              </TextBox>
            ))}
          </Stack>
        }
      >
        <Typography display="inline" noWrap={true} color="textPrimary">
          {title}
        </Typography>
      </Tooltip>
    </Inline>
  );
}
