import { Color } from "@superdispatch/ui";
import { createSvgIcon } from "./IconUtils";

export const DeliveryAltIcon = createSvgIcon(
  "PostingFilterDeliver",
  <>
    <circle cx="12" cy="12" r="10" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={Color.White}
      d="M7 12.913l1.196-1.217 2.966 3.036V6h1.676v8.732l2.966-3.036L17 12.913 11.996 18 7 12.913z"
    />
  </>
);
