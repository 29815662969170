import { Grid } from "@material-ui/core";
import { Color, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import styled from "styled-components";
import { joinStrings } from "shared/utils/StringUtils";
import { round, startCase } from "lodash-es";
import {
  RecentMovedLoadDTO,
  RecentPostingDTO,
} from "../../../data/CarrierPayDTO";

const GutterRoot = styled.div`
  height: 100%;
  padding: 4px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;

  &[data-start="true"] {
    background: ${Color.Yellow300};
  }

  &[data-middle="true"] {
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid ${Color.Dark100};
  }

  &[data-end="true"] {
    background: ${Color.Green300};
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 2px;
  background: repeating-linear-gradient(
    ${Color.Dark100},
    ${Color.Dark100} 3px,
    transparent 3px,
    transparent 5px
  );
`;

export function formatPostingAddress({
  city,
  state,
  zip,
}: {
  city: string | null;
  state: string | null;
  zip: string | null;
}): string {
  const cityAndState = joinStrings(
    ", ",
    startCase(city?.toLowerCase()),
    state?.toUpperCase()
  );

  return joinStrings(" ", cityAndState, zip);
}

function Gutter() {
  return (
    <GutterRoot>
      <GutterPoint data-start="true" />
      <GutterLine />
      <GutterPoint data-end="true" />
    </GutterRoot>
  );
}

export interface PostingWaypointsProps {
  load: RecentMovedLoadDTO | RecentPostingDTO;
}

export function LoadWaypoints({ load }: PostingWaypointsProps) {
  return (
    <Stack space="xxsmall">
      <Grid container={true} spacing={1} wrap="nowrap">
        <Grid item={true}>
          <Gutter />
        </Grid>

        <Grid item={true} xs={11}>
          <Stack space="xsmall">
            <TextBox
              noWrap={true}
              wrapOverflow={true}
              aria-label="Pickup address"
            >
              {formatPostingAddress(load.origin)}
            </TextBox>

            <TextBox
              noWrap={true}
              wrapOverflow={true}
              aria-label="Delivery address"
            >
              {formatPostingAddress(load.destination)}
            </TextBox>
          </Stack>
        </Grid>
      </Grid>

      <TextBox noWrap={true} wrapOverflow={true} aria-label="Delivery address">
        {round(load.distance_miles, 2)} mi
      </TextBox>
    </Stack>
  );
}
