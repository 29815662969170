import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import { Column, Columns } from "@superdispatch/ui";
import { HubSpotForm } from "./HubSpotForm";

const TextHeading4 = styled(Typography)`
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  font-family: "Metropolis", sans-serif;
`;

interface HubSpotFormDialogProps {
  open: boolean;
  onClose: () => void;
  onEntered: () => void;
  onSubmitSuccess: () => void;
}

export function HubSpotFormDialog({
  open,
  onClose,
  onEntered,
  onSubmitSuccess,
}: HubSpotFormDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onEntered }}>
      <DialogTitle disableTypography={true}>
        <Columns align="center">
          <Column>
            <TextHeading4>Discover the Magic Behind the Price!</TextHeading4>
          </Column>

          <Column width="content">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      <DialogContent>
        <HubSpotForm onSubmitSuccess={onSubmitSuccess} />
      </DialogContent>
    </Dialog>
  );
}
