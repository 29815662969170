import { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { useEventHandler } from "@superdispatch/hooks";
import { Inline } from "@superdispatch/ui";

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target?: string;
          onFormSubmit?: () => void;
          onFormReady?: () => void;
          onFormSubmitted?: () => void;
        }) => void;
      };
    };
  }
}

const FormContainer = styled.div`
  padding: 8px;
  padding-bottom: 32px;

  & {
    span,
    input,
    select {
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    select {
      font-size: 14px;
    }

    .form-columns-1,
    .form-columns-2 {
      padding-bottom: 24px;
    }

    .hs-input[type="text"],
    .hs-input[type="email"],
    select.hs-input {
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #c4cdd5;
      background: #fff;
      height: 56px;
      width: 100% !important;
    }

    .hs-form-booleancheckbox-display {
      display: flex;
      align-items: baseline;
    }

    .hs-input[type="checkbox"] {
      margin-right: 8px;
      min-width: 13px;
    }

    .hs-button {
      width: 100%;
      display: flex;
      padding: 16px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      outline: 0;

      color: #fff;

      /* Website Typography/Body L/Medium */
      font-family: Inter, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 136.364% */

      border: none;
      border-radius: 8px;
      background: #fe4a49;

      &:focus {
        outline: 2px solid #a8d1ff;
      }
    }

    .inputs-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .hs-submit .actions {
      display: flex;
      justify-content: center;
    }

    .hs-error-msgs {
      padding-top: 8px;
      padding-bottom: 8px;
      margin: 0;
      list-style: none;
      color: #c31909;
      font-size: 16px;
    }

    .hs-error-msg {
      color: #c31909;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

interface HubSpotFormProps {
  onSubmitSuccess: () => void;
}

export function HubSpotForm({ onSubmitSuccess }: HubSpotFormProps) {
  const [isLoaded, setLoaded] = useState(false);
  const onSubmitSuccessHandler = useEventHandler(onSubmitSuccess);

  useEffect(() => {
    if (!window.hbspt) {
      const script = document.createElement("script");
      script.id = "hubspotFormScript";
      script.src = "https://js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        createForm();
      });
    } else {
      createForm();
    }

    function createForm() {
      window.hbspt?.forms.create({
        region: "na1",
        portalId: "44142879",
        formId: "49336146-e4e7-4591-9c96-cc8b3f702d42",
        target: "#hubspotForm",
        onFormReady: () => {
          setLoaded(true);
        },
        onFormSubmitted: () => {
          onSubmitSuccessHandler(undefined);
        },
      });
    }
  }, []);

  return (
    <>
      {!isLoaded && (
        <Inline horizontalAlign="center">
          <CircularProgress />
        </Inline>
      )}
      <FormContainer id="hubspotForm" />
    </>
  );
}
