import { Color, Inline, Stack, useSnackbarStack } from "@superdispatch/ui";
import { Sync } from "@material-ui/icons";

import { Button, TextBox } from "@superdispatch/ui-lab";
import { Form, FormikContextType, FormikProvider } from "formik";
import { VenueField } from "./VenueField";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { PickupAltIcon } from "../../../icons/PickupAltIcon";
import { HorizontalSwapIcon } from "../../../icons/HorizontalSwapIcon";
import styled from "styled-components";
import { DeliveryAltIcon } from "../../../icons/DeliveryAltIcon";
import { TrailerTypeField } from "./TrailerTypeField";
import { VehiclesField } from "./VehiclesField";
import {
  ShippingDetailsDTO,
  shippingDetailsSchema,
} from "../../../data/ShippingDetailsDTO";
import { PricingInsightsResultDTO } from "../../../data/CarrierPayDTO";
import { trackCarrierPayEvent } from "../../../data/CarrierPayAnalytics";
import { useEffect, useMemo, useState } from "react";
import { useFormikEnhanced } from "@superdispatch/forms";
import {
  addLogContext,
  addMonitoringAction,
  logError,
  logInfo,
  logWarning,
} from "shared/services/MonitoringService";
import { usePricingInsightsAPI } from "../../../data/CarrierPayAPI";
import { dequal } from "dequal/lite";
import { parseSearchQuery } from "../../../services/AnalyticsService";
import { shippingDetailsParamsSchema } from "../../../data/ShipperDetailsParamsDTO";

const VenuesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  & > :nth-child(1),
  & > :nth-child(3) {
    flex: 1;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 32px;
  max-width: 632px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};

  @media (min-width: 760px) {
    height: max(100vh - 112px, 800px);
    overflow: auto;
  }
`;

const SwapButton = styled(IconButton)`
  padding: 5px;
`;

const defaultValues = {
  origin: null,
  destination: null,
  trailer_type: "Open",
  vehicles: [
    {
      $key: "vehicle-key1",
      model: null,
      make: null,
      type: null,
      is_inoperable: false,
      year: null,
    },
  ],
} as unknown as ShippingDetailsDTO;

function useHasInitialError(
  { submitCount, isValid }: FormikContextType<ShippingDetailsDTO>,
  params: ShippingDetailsDTO
): boolean {
  const [initialError, $initialError] = useState(false);

  useEffect(() => {
    const isParamsEmpty = dequal(defaultValues, params);
    // capture initial submission error
    if (!isParamsEmpty && submitCount === 1 && !isValid) {
      $initialError(true);
    }
  }, [params, isValid, submitCount]);

  // true if initial submission is error but current form is valid
  return submitCount === 1 && initialError && isValid;
}

export function ShippingDetailsForm({
  pricingInsights,
  onSubmit,
  onSubmitSuccess,
}: {
  pricingInsights: PricingInsightsResultDTO | null;
  onSubmit: (values: ShippingDetailsDTO) => void;
  onSubmitSuccess: (values: PricingInsightsResultDTO) => void;
}) {
  const { addSnackbar } = useSnackbarStack();
  const {
    getRecentPostings,
    getRecentMoves,
    calculateCarrierPay,
    getCoordinates,
  } = usePricingInsightsAPI();

  const shippingDetailsParams = useMemo(() => {
    try {
      return shippingDetailsParamsSchema.cast(
        parseSearchQuery(location.search, { arrayLimit: 100 })
      ) as ShippingDetailsDTO;
    } catch (error) {
      logError(error, "parseShippingParams");
      return shippingDetailsParamsSchema.cast({}) as ShippingDetailsDTO;
    }
  }, [location.search]);

  const formik = useFormikEnhanced<
    ShippingDetailsDTO,
    PricingInsightsResultDTO
  >({
    initialValues: shippingDetailsParams,
    validationSchema: shippingDetailsSchema,
    enableReinitialize: false,
    onSubmit: async function (values) {
      const payload = shippingDetailsSchema.cast(values);

      try {
        const coordinates = await getCoordinates({
          origin: payload.origin,
          destination: payload.destination,
        });

        Object.assign(payload.origin, coordinates.origin);
        Object.assign(payload.destination, coordinates.destination);
      } catch (error) {
        logWarning("Failed to call coordinates", { error, payload });
      }

      addLogContext("shipping_details", payload);
      onSubmit(payload);

      const results = await Promise.allSettled([
        calculateCarrierPay(payload),
        getRecentPostings(payload),
        getRecentMoves(payload),
      ]);
      const [carrierPay, recentPostings, recentMoves] = results;

      if (
        results.every((x) => x.status === "rejected") &&
        carrierPay.status === "rejected"
      ) {
        throw carrierPay.reason;
      }

      if (carrierPay.status === "rejected") {
        addSnackbar(
          "Failed to calculate carrier price. " + carrierPay.reason.message,
          { variant: "error" }
        );
      } else if (recentMoves.status === "rejected") {
        addSnackbar(
          "Failed to load recently moved loads. " + recentMoves.reason.message,
          { variant: "error" }
        );
      } else if (recentPostings.status === "rejected") {
        addSnackbar(
          "Failed to load Super Loadboard postings. " +
            recentPostings.reason.message,
          { variant: "error" }
        );
      }

      return {
        carrier_pay:
          carrierPay.status === "fulfilled" ? carrierPay.value : null,
        recent_moves:
          recentMoves.status === "fulfilled" ? recentMoves.value : [],
        recent_postings:
          recentPostings.status === "fulfilled" ? recentPostings.value : [],
      };
    },
    onSubmitSuccess(response, values) {
      trackCarrierPayEvent({
        name: "Calculated Carrier Pay Price",
        isRecalculated: !!pricingInsights,
        shippingDetails: values,
      });
      // reset dirty state
      formik.resetForm({ values, submitCount });
      onSubmitSuccess(response);
      logInfo("Calculated pricing insights", values);
    },
    onSubmitFailure(error) {
      logWarning("Failed to calculate", { error });
      addSnackbar(error.message || "Failed to calculate", { variant: "error" });
    },
  });

  const { setValues, resetForm, isSubmitting, dirty, values, submitCount } =
    formik;

  const hasInitialError = useHasInitialError(formik, shippingDetailsParams);
  const isEmptyValues = useMemo(
    () => dequal(defaultValues, values),
    [values, defaultValues]
  );

  useEffect(() => {
    const isParamsEmpty = dequal(defaultValues, shippingDetailsParams);
    if (!isParamsEmpty) {
      void formik.submitForm();
    }
  }, [shippingDetailsParams]);

  return (
    <Content>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <TextBox variant="heading-2">Shipping Details</TextBox>

            <Stack>
              <Stack>
                <Inline space="xxsmall">
                  <TextBox variant="heading-4">Route</TextBox>
                  <TextBox variant="heading-4" color="secondary">
                    (Required)
                  </TextBox>
                </Inline>
              </Stack>

              <VenuesContainer>
                <VenueField
                  name="origin"
                  TextFieldProps={{
                    placeholder: "Enter Pickup City or ZIP",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <PickupAltIcon htmlColor={Color.Yellow400} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />

                <SwapButton
                  color="default"
                  aria-label="swap locations"
                  onClick={() => {
                    setValues(
                      ({ origin, destination, ...rest }) => ({
                        ...rest,
                        origin: destination,
                        destination: origin,
                      }),
                      true
                    );
                  }}
                >
                  <HorizontalSwapIcon />
                </SwapButton>

                <VenueField
                  name="destination"
                  TextFieldProps={{
                    placeholder: "Enter Delivery City or ZIP",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <DeliveryAltIcon htmlColor={Color.Green300} />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </VenuesContainer>
            </Stack>

            <Stack space="small">
              <TextBox variant="heading-4">Trailer Type</TextBox>

              <TrailerTypeField name="trailer_type" />
            </Stack>

            <Stack space="small">
              <TextBox variant="heading-4">Vehicle</TextBox>

              <VehiclesField name="vehicles" />
            </Stack>

            <Stack>
              <Inline>
                {pricingInsights && dirty ? (
                  <Button
                    type="submit"
                    variant="primary"
                    pending={isSubmitting}
                    startIcon={<Sync />}
                    onClick={() => {
                      addMonitoringAction("recalculate");
                    }}
                  >
                    Recalculate
                  </Button>
                ) : (
                  <Tooltip open={hasInitialError} title="Click to calculate">
                    <Button
                      type="submit"
                      variant="primary"
                      pending={isSubmitting}
                    >
                      Calculate
                    </Button>
                  </Tooltip>
                )}

                {!isEmptyValues && (
                  <Button
                    disabled={isSubmitting}
                    variant="neutral"
                    onClick={() => {
                      trackCarrierPayEvent({
                        name: "Cleared Fields",
                      });
                      resetForm({ values: defaultValues, submitCount });
                    }}
                  >
                    Clear
                  </Button>
                )}
              </Inline>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </Content>
  );
}
