import { useVehicleType } from "../../../data/VehiclesAPI";
import { TextField } from "@material-ui/core";
import { formatVehicleType } from "@superdispatch/sdk";
import { useField } from "formik";
import { useValueObserver } from "@superdispatch/hooks";

function mapVehicleType(value: string) {
  return value === "pickup_2_doors"
    ? "2_door_pickup"
    : value === "pickup_4_doors"
    ? "4_door_pickup"
    : value === "coupe_2_doors"
    ? "2_door_coupe"
    : value;
}

export function VehicleTypeField({
  name,
  make,
  model,
}: {
  name: string;
  make: string | null;
  model: string | null;
}) {
  const { data } = useVehicleType(make, model);
  const [{ value }, { error, touched }, { setValue }] = useField({
    name,
  });

  useValueObserver(data, () => {
    setValue(data);
  });

  return (
    <TextField
      label="Type"
      fullWidth={true}
      disabled={true}
      error={Boolean(touched && error)}
      helperText={touched && error}
      InputProps={{ readOnly: true }}
      value={formatVehicleType(mapVehicleType(value), { fallback: "" })}
    />
  );
}
