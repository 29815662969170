export const LogoWithBrand = () => {
  return (
    <svg width="26" height="32" viewBox="0 0 26 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.97749 7.94393C2.96897 3.23911 7.8668 0 12.9431 0C18.0864 0 22.9061 3.19995 24.9088 7.94393C27.1569 13.2585 25.2323 18.5731 22.0471 23.015C19.626 26.3996 16.7755 29.2974 13.6014 31.9715C13.5567 32.0163 13.4842 32.0051 13.4452 31.9603C13.1663 31.6495 12.9001 31.3239 12.6336 30.9977C12.4683 30.7955 12.3029 30.5931 12.1343 30.3939C12.0896 30.3492 12.1008 30.2764 12.1454 30.2373C14.929 27.8933 17.4784 25.3478 19.7097 22.4668C21.8016 19.7591 23.6536 16.5368 23.6536 13.0124C23.6536 7.22226 18.7223 2.27689 12.932 2.27689C7.10256 2.27689 2.21031 7.17751 2.21031 13.0124C2.21031 14.7914 2.9634 17.7843 6.56703 21.9297C8.01741 23.608 9.49011 24.9339 10.3269 25.6555C11.2027 24.8611 12.7814 23.3675 14.3489 21.5717C17.3222 18.1536 18.8953 15.1942 18.8953 13.0124C18.8953 9.71173 16.2176 7.02646 12.9208 7.02646C9.62957 7.02646 6.94636 9.71173 6.94636 13.0124C6.94636 15.9649 9.54036 18.3147 11.5836 20.1657C11.6697 20.2436 11.7547 20.3207 11.8386 20.3969C11.8832 20.436 11.8832 20.5088 11.8442 20.5535C11.3868 21.0626 10.9293 21.5661 10.4719 22.0696C10.4329 22.1143 10.3603 22.1143 10.3157 22.0752C7.7106 19.7088 4.68154 16.8165 4.68154 13.0124C4.68154 8.61525 8.55294 4.74957 12.932 4.74957C17.3891 4.74957 21.1824 8.54811 21.1824 13.0124C21.1824 15.7928 19.4587 19.1885 16.0614 23.0877C14.3377 25.0625 12.4522 26.8695 10.444 28.5534C10.405 28.5869 10.3436 28.5869 10.3046 28.5534C8.36885 26.9758 6.51125 25.2975 4.8712 23.4122C3.47103 21.7955 2.34419 20.1955 1.53533 18.6626C-0.249754 15.2725 -0.534252 11.5075 0.97749 7.94393ZM12.9431 9.48236C14.8844 9.48236 16.4686 11.06 16.4686 13.0124C16.4686 14.9648 14.89 16.5424 12.9431 16.5424C10.9962 16.5424 9.41756 14.9648 9.41756 13.0124C9.41756 11.06 11.0018 9.48236 12.9431 9.48236ZM11.3979 13.0124C11.3979 13.8627 12.0896 14.5564 12.9431 14.5564C13.7966 14.5564 14.4883 13.8627 14.4883 13.0124C14.4883 12.162 13.7966 11.4683 12.9431 11.4683C12.0896 11.4683 11.3979 12.162 11.3979 13.0124Z"
        fill="#FE4A49"
      />
    </svg>
  );
};
