import { array, boolean, InferType, number, object, string } from "yup";

const recentMoveVenueSchema = object({
  city: string().defined().nullable(),
  state: string().defined().nullable(),
  zip: string().defined().nullable(),
});

export type RecentMovedLoadVehicleDTO = InferType<
  typeof recentMoveVehicleSchema
>;
const recentMoveVehicleSchema = object({
  type: string().nullable(), // only nullable for recent postings
  make: string().nullable(),
  model: string().nullable(),
  year: number().nullable(),
  is_inoperable: boolean().default(false),
  requires_enclosed_trailer: boolean().default(false),
}).noUnknown();

export type RecentMovedLoadDTO = InferType<typeof recentMovedLoadSchema>;
export const recentMovedLoadSchema = object({
  dispatched_date: string().defined(),
  delivered_date: string().defined(),
  origin: recentMoveVenueSchema,
  destination: recentMoveVenueSchema,
  price: number().defined(),
  distance_miles: number().defined(),
  price_per_mile: number()
    .defined()
    .transform((value) => value || 0),
  price_per_vehicle: number()
    .defined()
    .transform((value) => value || 0),
  price_per_mile_per_vehicle: number()
    .defined()
    .transform((value) => value || 0),
  vehicles: array(recentMoveVehicleSchema).defined(),
});

export type RecentPostingDTO = InferType<typeof recentPostingSchema>;
export const recentPostingSchema = object({
  posted_date: string().defined(),
  pickup_date: string().defined().nullable(),
  origin: recentMoveVenueSchema,
  destination: recentMoveVenueSchema,
  price: number().defined(),
  distance_miles: number().defined(),
  price_per_mile: number()
    .defined()
    .transform((value) => value || 0),
  price_per_vehicle: number()
    .defined()
    .transform((value) => value || 0),
  price_per_mile_per_vehicle: number()
    .defined()
    .transform((value) => value || 0),
  vehicles: array(recentMoveVehicleSchema).defined(),
});

export type CarrierPayDTO = InferType<typeof carrierPaySchema>;
export const carrierPaySchema = object({
  total_price: number().defined(),
  distance: number().defined(),
  price_per_km: number().defined(),
});

// temporary type to union all types
export type PricingInsightsResultDTO = {
  carrier_pay: CarrierPayDTO | null;
  recent_moves: RecentMovedLoadDTO[];
  recent_postings: RecentPostingDTO[];
};
