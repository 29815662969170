import { PricingInsightsPage } from "./pages/carrier-pay/PricingInsightsPage";
import { useEffect } from "react";
import {
  getAnonymousId,
  identifyUser,
  trackEvent,
} from "./services/AnalyticsService";
import { identifyUser as identifyMonitoringUser } from "shared/services/MonitoringService";
import { MarketingLayout } from "./MarketingLayout";
import { AppLayout } from "./AppLayout";
import { hideIntercomLauncher } from "./services/IntercomService";
import { isUserFromSuperDispatch } from "./pages/carrier-pay/unlock/UnlockUtils";

export function App() {
  useEffect(() => {
    hideIntercomLauncher();
    trackEvent("Opened Pricing Insights");

    identifyUser().then((analyticsUserId) => {
      const analyticsAnonymousId = getAnonymousId();

      identifyMonitoringUser({
        analytics_user_id: analyticsUserId,
        analytics_anonymous_id: analyticsAnonymousId,
      });
    });
  }, []);

  const Layout = isUserFromSuperDispatch() ? AppLayout : MarketingLayout;
  return (
    <Layout>
      <PricingInsightsPage />
    </Layout>
  );
}
