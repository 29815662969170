import { array, boolean, InferType, number, object, string } from "yup";
import { dequal } from "dequal/lite";
import {
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleTypes,
} from "./VehiclesAPI";
import { uniqueId } from "lodash-es";

export function generateVehicleKey() {
  return uniqueId("vehicle-key");
}

export type CarrierPayVehicleDTO = InferType<typeof carrierPayVehicleSchema>;
export const carrierPayVehicleSchema = object({
  $key: string().default(generateVehicleKey),
  year: number()
    .nullable()
    .required("Enter Year")
    .min(1900, "Year must be greater than 1900")
    .transform((value, originalValue) => {
      // do not throw error for empty value
      return originalValue === "" ? null : value;
    }),
  make: string()
    .nullable()
    .required("Enter Make")
    .test("validateMake", "Re-select the Make", async (value) => {
      const makes = await fetchVehicleMakes();
      return makes.some((make) => make.toLowerCase() === value.toLowerCase());
    }),
  model: string()
    .nullable()
    .required("Enter Model")
    .test("validateModel", "Re-select the Model", async (value) => {
      const models = await fetchVehicleModels();
      return models.some(
        (model) => model.toLowerCase() === value.toLowerCase()
      );
    }),
  type: string()
    .nullable()
    .required("Choose proper Model")
    .test("validateType", "Re-select the Model", async (value) => {
      const types = await fetchVehicleTypes();
      return types.includes(value.toLowerCase());
    }),
  is_inoperable: boolean().default(false),
}).noUnknown();

export type CarrierPayVenueDTO = InferType<typeof carrierPayVenueSchema>;
export const carrierPayVenueSchema = object({
  zip: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
});

export type ShippingDetailsDTO = InferType<typeof shippingDetailsSchema>;
export const shippingDetailsSchema = object({
  origin: carrierPayVenueSchema.required("Enter Pickup address"),
  destination: carrierPayVenueSchema
    .required("Enter Delivery address")
    .test(
      "same-venues",
      "Must differ from Origin",
      (value, context) => !dequal(value, context.parent.origin)
    ),
  trailer_type: string().nullable(),
  vehicles: array(carrierPayVehicleSchema).ensure(),
  model_version: string().nullable(),
  service: string().nullable(),
});

export type VenueLocationDTO = InferType<typeof venueLocationSchema>;
export const venueLocationSchema = object({
  origin: carrierPayVenueSchema,
  destination: carrierPayVenueSchema,
});
